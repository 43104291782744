import React, {useEffect, useRef, useContext, lazy} from 'react';
import './Hero.css'
import { Button } from './Button';

import {fnc_scrollto} from './miscItems.js'
import { NavBarContext } from '../contexts/NavBarContext';
import {retry} from './miscItems.js'
const Cards = lazy(()  => retry(() => import('../components/Cards')));


function Hero() {
        const parallaxRef = useRef();
        const contentRef = useRef();
        const contentRefAlt = useRef();
        const flexGapSupport = useContext(NavBarContext)[2]


        useEffect(() => {
              let parallaxScrollTimer = setTimeout(() => { 
                parallaxRef.current.scrollTop=1;
            }, 450);
              return () => {
                clearTimeout(parallaxScrollTimer);
              };
            },[parallaxRef]);


        const heroContentScroll = () =>{

            if('scrollBehavior' in document.documentElement.style){
                parallaxRef.current.scroll(
                    {
                        top: contentRef.current.getBoundingClientRect().top-90,
                        behavior: 'smooth'
                      })
               }
               else{
                   fnc_scrollto('ref',contentRef, -90, parallaxRef);
               }
        }

    return (


        <div className="parallax" ref={parallaxRef} id="parallax">


        <div className="video_contain">
               <video  autoPlay loop muted id="introVideo" preload="metadata">

                     <source src="images/WagyuVidIntro.mp4#t=0.1" type='video/mp4' />
                 </video> 
        </div>
        

        <div className="parallaxLayer parallaxHeroContent">
            <div className="heroContent">
        
                <h1 className="heroTitle">The Highest Quality Beef in the World</h1>
                <h2 className="heroSubtitle">Shop our selection of imported Japanese A5 Wagyu and Argintinean grassfed beef</h2>

<div className="heroButtonContainer">

                <div className="heroButton">
                    <Button link="/About" passedClass=" expandedButton transparentBtn">
                        &nbsp; Learn More  &nbsp;
                    </Button>
                </div>
                {!flexGapSupport && <div style={{width:'2rem'}}/>}
                <div className="heroButton">
                    <Button link="/shop" passedClass="expandedButton  chevreButton">

                        &nbsp; Shop Now   <i className="fas fa-chevron-right buttonChevre"></i>
                    </Button>
                </div>

                </div>

            </div>
                                

         </div>


         <div ref ={contentRefAlt} className={`parallaxScrollDown`}>
                        <div className="scrollDownButton" onClick={()=>heroContentScroll()}>
                        <i className="fa fa-angle-down"></i>
                        </div>
                    </div>
        <div ref={contentRef} className='heroContainer'>




     <Cards></Cards>





     
    </div>
    </div>
    );
    }

    export default Hero;