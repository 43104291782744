import React, { useState, useEffect, createContext } from "react";
import axios from 'axios'



export const ProductCatalogContext = createContext();

export const ProductCatalogProvider = (props) => {
    const [productCatalog, setProductCatalog] = useState()
    const [productDataLoaded, setproductDataLoaded] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
          const result = await axios(
              'https://fn2ugsghva.execute-api.us-west-1.amazonaws.com/Production',
          );

          var productData = {}
          Object.keys(result.data.Items).forEach(key => {
            const val = result.data.Items[key];
            productData= {...productData, [val.ProductName.S]:val}
         });
          setProductCatalog(productData)
          setproductDataLoaded(true);
        };
        fetchData();
      }, []);


    return (
        <ProductCatalogContext.Provider value={[productCatalog, productDataLoaded]}>
        {props.children}
        </ProductCatalogContext.Provider>
    )
}