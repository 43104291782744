import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

function Button({buttonDisable, passedClass, link, children, onClick, buttonError }) {
  return (
    <>
    {link? <Link to={link}>
    <button
        disabled={buttonDisable}
      className={`button ${passedClass} ${buttonError? "buttonError":undefined}`}
      onClick={onClick}
    >{children}</button>
  </Link>:

      <button
      disabled={buttonDisable}
      className={`button  ${passedClass} ${buttonError? "buttonError":undefined}`}
      onClick={onClick}

    >
      {children}

    </button>
  
  }
  </>
  );
}

export  {Button};