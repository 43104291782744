import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CartProvider} from './contexts/CartContext';
import {NavBarProvider} from './contexts/NavBarContext';
import { ProductCatalogProvider } from './contexts/ProductCatalogContext';
import {BrowserRouter as Router} from  'react-router-dom';

ReactDOM.render(
  <React.StrictMode>

    <CartProvider>
       <NavBarProvider>
          <ProductCatalogProvider>
          <Router>
            <App />
            </Router>
          </ProductCatalogProvider>
        </NavBarProvider>
    </CartProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
