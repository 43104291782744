import React, { useState, createContext } from "react";



export const NavBarContext = createContext();

export const NavBarProvider = (props) => {
    const [navBarShowState, setNavBarShowState] = useState(0)
    const [flexGapSupport, setFlexGapSupport] = useState(false)
    const [shippingBanner, setShippingBanner] = useState(true)

    return (
        <NavBarContext.Provider value={[navBarShowState, setNavBarShowState, flexGapSupport, setFlexGapSupport, shippingBanner, setShippingBanner]}>
        {props.children}
        </NavBarContext.Provider>
    )
}