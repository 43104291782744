import React, {useContext} from 'react';
import { CartContext } from '../contexts/CartContext';
import { Button } from './Button';
import './Cart.css'
import { Link } from 'react-router-dom';
import {AnimatePresence,  motion } from "framer-motion"
import { NavBarContext } from "../contexts/NavBarContext";


function Cart({shouldShow, cartCloseMenu, viewPortHeight}) {

    const [cart, setCart] = useContext(CartContext);
    const flexGapSupport = useContext(NavBarContext)[2]



 







    const  handleMinusQty = (key) => {
        var updatedQtyItem = cart[key]
        if (updatedQtyItem.qty>1) {
        updatedQtyItem.qty --;

        const newCart = {
            ...cart,
            [key]: updatedQtyItem
        }
        setCart(newCart)
        localStorage.setItem('cart', JSON.stringify(newCart));
    }
    }
    const  handlePlusQty = (key) => {
        var updatedQtyItem = cart[key]
        updatedQtyItem.qty ++;

        const newCart = {
            ...cart,
            [key]: updatedQtyItem
        }
        setCart(newCart)
    localStorage.setItem('cart', JSON.stringify(newCart));

    }


    const  deleteCartItem = (key) => {
        const newCart = Object.keys(cart).reduce(
            (acc, cur) => cur === key ? acc : {...acc, [cur]: cart[cur]}, {})
        setCart(newCart)
    localStorage.setItem('cart', JSON.stringify(newCart));
    }

    const cartTotal = () => {
        const total = Object.keys(cart).reduce((acc, key) => {
            return acc + cart[key].qty*cart[key].price;
          }, 0);
          return total;
    }





    return (

        
        <div 
        style = {{height:`max(25rem, ${viewPortHeight})`}}
        className= {shouldShow? "cartContainer cartShow" :"cartContainer"} >

        <div className="outerCartContainer">
        <div
         className= {shouldShow? "innerCartContainer innerCartSlide" :"innerCartContainer"}
        >



            <div
            className="sideCartItemContainer"
            >



            {Object.keys(cart).length? 


                <>

                <div className="closeCartButton">
                <span className="alignleft"  onClick={cartCloseMenu}>
                <div
                 className= {shouldShow? "closeCartButtonEx" :"closeCartButtonEx collapsedButton"}
                />
                </span>
                <span  className= {shouldShow? "aligncenter showCartTitle" :"aligncenter "}
                >Cart</span>
                <span className="alignright">&nbsp;</span>
                </div>
                <div className="fullWidthCartVertSpacer"/>
                

            <div 
            
            style={{opacity:"0", transition:'all 0.01s ease 0.6s'}}

            className= {shouldShow? " fadeInSideCartItems" :""}
               >
            <AnimatePresence>



            {Object.keys(cart).map((key) => 


                    <motion.div 
                    className= "sideCartItems"
                     key={key}
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0, height:0}}>


                <Link 
                className="sideCartItemImage"
                aria-label={cart[key].name}
                to={`/shop/${cart[key].name.replace(/\s+/g, '_')}`} onClick={cartCloseMenu}>
                    <img width="259px" height="173px"   className="itemImage" src={cart[key].img+"Tiny.jpg"} alt={cart[key].name}/>
                </Link>
                {!flexGapSupport && 
                <div                 
                className="sideCartItemImage"
                style={{width:"1rem", minWidth:"1rem"}}/>}


                <div className="name">
                <Link to={`/shop/${cart[key].name.replace(/\s+/g, '_')}`} onClick={cartCloseMenu} className="cartLinkName" aria-label={cart[key].name}>
                    {cart[key].name}
                </Link>
                <div className="portion">
                <Link to={`/shop/${cart[key].name.replace(/\s+/g, '_')}`} onClick={cartCloseMenu} className="cartLinkName" aria-label={cart[key].name}>
                ({cart[key].portion})
                </Link>
                    </div>
                <div className="delete" onClick={() => deleteCartItem(key)}>Remove</div>
                </div>
                {!flexGapSupport && <div style={{width:"1rem"}}/>}

                <div className="priceQty">
                <div className="itemPrice">${(cart[key].price*cart[key].qty).toFixed(2)}</div>
                <div className="quantityMod">
                <button disabled={!(cart[key].qty-1)} className="editCartLeftQty" onClick={() => handleMinusQty(key)}>-</button>
                <div className="qtyText" value={cart[key].qty} type="number">{cart[key].qty}</div>
                <button  className="rightQtyButton" onClick={() => handlePlusQty(key)}>+</button>
                </div>
                </div>

           
            </motion.div>

            )}
            </AnimatePresence>
            </div>
            </>

            :
            <>
            <div className="closeCartButton" onClick={cartCloseMenu}>
            <span className="alignleft"  onClick={cartCloseMenu}>
            <div
                 className= {shouldShow? "closeCartButtonEx" :"closeCartButtonEx collapsedButton"}
                />
                </span>
            </div>
            <div className="fullWidthCartVertSpacer"/>

            <div className={shouldShow? "showCartTitle emptyCartTitle" :"emptyCartTitle"}>Your Cart is Empty</div>
            </>

            }
            </div>
            
            <div className={shouldShow? "subTotalContainer subTotalBorder" :"subTotalContainer"}
            >

            <div 
         className= {shouldShow? "subTotalPriceContainer showSubTotal" :"subTotalPriceContainer"}

            >
                <div className="subTotalTag"> Subtotal:</div>
                <div className="subTotalPrice">${cartTotal().toFixed(2)}</div>
            </div>
            <div className="checkoutButtonContainer">


            {Object.keys(cart).length?  
            <>  
            <div className="cartButtonContainer">
                <div className={shouldShow? "innerCartButtonContainer fadeInEditButton" :"innerCartButtonContainer"}>
                <Button passedClass="expandedButton  transparentBtn" link='/Cart' onClick={cartCloseMenu}>
                Edit Cart
                </Button>
                </div>
            </div>
            <div className="cartButtonSapcer"/>
            <div className="cartButtonContainer">
            
            <div className={shouldShow? "innerCartButtonContainer fadeInCheckoutButton" :"innerCartButtonContainer"}>
                <Button passedClass="expandedButton " link='/Cart/Checkout' onClick={cartCloseMenu}>
                Checkout
                </Button>
                </div>

            </div>
            </>
            :
            <>
            <div className="cartButtonContainer">
                <div className={shouldShow? "innerCartButtonContainer fadeInEditButton" :"innerCartButtonContainer"}>
               <Button passedClass="expandedButton " link='/shop' onClick={cartCloseMenu}>
            Continue Shopping
            </Button>
            </div>
            </div>
            <div style={{height:"2.5rem"}}></div>
            </>
            }


            </div>        </div>


            </div>
            </div>
        </div>
    );
}

export default Cart;