import React, {useState, useEffect} from 'react';
import './NavMenu.css'
import { Link } from 'react-router-dom';


function NavMenu({shouldShow, cartCloseMenu, viewPortHeight}) {


    const[showShopSubMenu, setShowShopSubMenu] = useState(false)
    const[showAboutSubMenu, setShowAboutSubMenu] = useState(false)


    useEffect(() => {

        if (!shouldShow){
            setTimeout(() => {

            setShowAboutSubMenu(false)
            setShowShopSubMenu(false)
        }, 400);

        }


    }, [shouldShow]);


    return (
        <div 
        style = {{height:`max(25rem, ${viewPortHeight})`}}
        className= {shouldShow? "menuContainer menuShow" :"menuContainer"} >

        <div className="outerMenuContainer">
        <div 
         className= {shouldShow? "innerMenuContainer innerMenuSlide" :"innerMenuContainer"}
        >
            <div className="">
            <div className="closeMenuButtonConTainer">
                <span className="menuRightButton"  onClick={cartCloseMenu}>
                <div
                 className= {shouldShow? "closeMenuEx" :"closeMenuEx collapsedButton"}
                />
                </span>
            </div>
            <div className="menuNavContainer">

                <div className="menuNavTopLink" >

                    <Link to="/" href="/" className="menuNavLink" aria-label="Wagyu Outlet Home">Home </Link>

                </div>

            </div>

            <div className={`menuNavContainer ${showShopSubMenu? "expandShopMenu":undefined}`}>
            <div className="menuNavTopLink">

            <Link to="/Shop" href="/" className="menuNavLink" aria-label="Shop Japanese Wagyu and Argentinean Beef">Shop </Link>
                    <div className="menuPlus" onClick={()=>setShowShopSubMenu(!showShopSubMenu)}>
                    <div className={`menuSectionButton ${showShopSubMenu? "collapsedButton":undefined}`}
                        ></div>
                    </div>
                 </div>

                 <Link to="/Shop" href="/Shop#all" className="menuSubLink" aria-label="Shop All">Shop All</Link>
                 <Link to="/Shop#a5wagyu" href="/Shop#a5wagyu" className="menuSubLink" aria-label="Shop Japanese A5 Wagyu">Japanese A5 Wagyu</Link>
                 <Link to="/Shop#a4wagyu" href="/Shop#a4wagyu" className="menuSubLink" aria-label="Shop Japanese A4 Wagyu">Japanese A4 Wagyu</Link>
                 <Link to="/Shop#argentinian" href="/Shop#argentinian" className="menuSubLink" aria-label="Shop Argentinean Beef">Argentinean Beef</Link>

            </div>

            <div className={`menuNavContainer ${showAboutSubMenu? "expandAboutMenu":undefined}`}>
            <div className="menuNavTopLink">

                 <Link to="/About" href="/About" className="menuNavLink" aria-label="About Our Beef">About</Link>

                    <div className="menuPlus" onClick={()=>setShowAboutSubMenu(!showAboutSubMenu)}>
                        <div className={`menuSectionButton ${showAboutSubMenu? "collapsedButton":undefined}`}
                        ></div>
                    </div>
                 </div>
                 <Link to="/About#Japanese-Wagyu" href="/About#Japanese-Wagyu" className="menuSubLink" aria-label="About Japanese Wagyu">Japanese Wagyu</Link>

                 <Link to="/About#Argentinean-Beef" href="/About#Argentinean-Beef" className="menuSubLink" aria-label="About Argentinean Beef">Argentinean Beef</Link>
 

                 </div>

            </div>

            <div className= {shouldShow? "navMenuSocialContainer showSocialMenu" :"navMenuSocialContainer"} >
                    <a href='https://www.instagram.com/wagyuoutlet/'  rel="noreferrer" target="_blank" aria-label="Instagram">

                    <i className="fa fa-instagram menuSocialIcon" aria-hidden="true"></i>
                    </a>
                    <a href='https://twitter.com/WagyuOutlet' rel="noreferrer"  target="_blank" aria-label="Twitter">

                    <i className="fa fa-twitter-square menuSocialIcon" aria-hidden="true"></i>
                    </a>

                    <a href='https://www.facebook.com/Wagyu-Outlet-100277598749729'  rel="noreferrer" target="_blank" aria-label="Facebook">

                    <i className="fa fa-facebook-square menuSocialIcon" aria-hidden="true"></i>
                    </a>
            </div>
 
        </div>

      


        </div>
        </div>
    );
}

export default NavMenu;