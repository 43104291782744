const stateOptions = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
]



function zipState(zipString) {
  
    /* Ensure we don't parse strings starting with 0 as octal values */
    const zipcode = parseInt(zipString, 10); 
  
    let st;

  
    /* Code cases alphabetized by state */
    if (zipcode >= 35000 && zipcode <= 36999) {
        st = 'AL';

    } else if (zipcode >= 99500 && zipcode <= 99999) {
        st = 'AK';

    } else if (zipcode >= 85000 && zipcode <= 86999) {
        st = 'AZ';

    } else if (zipcode >= 71600 && zipcode <= 72999) {
        st = 'AR';

    } else if (zipcode >= 90000 && zipcode <= 96699) {
        st = 'CA';

    } else if (zipcode >= 80000 && zipcode <= 81999) {
        st = 'CO';

    } else if (zipcode >= 6000 && zipcode <= 6999) {
        st = 'CT';

    } else if (zipcode >= 19700 && zipcode <= 19999) {
        st = 'DE';

    } else if (zipcode >= 32000 && zipcode <= 34999) {
        st = 'FL';

    } else if (zipcode >= 30000 && zipcode <= 31999) {
        st = 'GA';

    } else if (zipcode >= 96700 && zipcode <= 96999) {
        st = 'HI';

    } else if (zipcode >= 83200 && zipcode <= 83999) {
        st = 'ID';

    } else if (zipcode >= 60000 && zipcode <= 62999) {
        st = 'IL';

    } else if (zipcode >= 46000 && zipcode <= 47999) {
        st = 'IN';

    } else if (zipcode >= 50000 && zipcode <= 52999) {
        st = 'IA';

    } else if (zipcode >= 66000 && zipcode <= 67999) {
        st = 'KS';

    } else if (zipcode >= 40000 && zipcode <= 42999) {
        st = 'KY';

    } else if (zipcode >= 70000 && zipcode <= 71599) {
        st = 'LA';

    } else if (zipcode >= 3900 && zipcode <= 4999) {
        st = 'ME';

    } else if (zipcode >= 20600 && zipcode <= 21999) {
        st = 'MD';

    } else if ( (zipcode >= 1000 && zipcode <= 2799) || (zipcode === 5501) ) {
        st = 'MA';

    } else if (zipcode >= 48000 && zipcode <= 49999) {
        st = 'MI';

    } else if (zipcode >= 55000 && zipcode <= 56899) {
        st = 'MN';

    } else if (zipcode >= 38600 && zipcode <= 39999) {
        st = 'MS';

    } else if (zipcode >= 63000 && zipcode <= 65999) {
        st = 'MO';

    } else if (zipcode >= 59000 && zipcode <= 59999) {
        st = 'MT';

    } else if (zipcode >= 27000 && zipcode <= 28999) {
        st = 'NC';

    } else if (zipcode >= 58000 && zipcode <= 58999) {
        st = 'ND';

    } else if (zipcode >= 68000 && zipcode <= 69999) {
        st = 'NE';

    } else if (zipcode >= 88900 && zipcode <= 89999) {
        st = 'NV';

    } else if (zipcode >= 3000 && zipcode <= 3899) {
        st = 'NH';

    } else if (zipcode >= 7000 && zipcode <= 8999) {
        st = 'NJ';

    } else if (zipcode >= 87000 && zipcode <= 88499) {
        st = 'NM';

    } else if (zipcode >= 10000 && zipcode <= 14999) {
        st = 'NY';

    } else if (zipcode >= 43000 && zipcode <= 45999) {
        st = 'OH';

    } else if (zipcode >= 73000 && zipcode <= 74999) {
        st = 'OK';

    } else if (zipcode >= 97000 && zipcode <= 97999) {
        st = 'OR';

    } else if (zipcode >= 15000 && zipcode <= 19699) {
        st = 'PA';

    } else if (zipcode >= 300 && zipcode <= 999) {
        st = 'PR';

    } else if (zipcode >= 2800 && zipcode <= 2999) {
        st = 'RI';

    } else if (zipcode >= 29000 && zipcode <= 29999) {
        st = 'SC';

    } else if (zipcode >= 57000 && zipcode <= 57999) {
        st = 'SD';

    } else if (zipcode >= 37000 && zipcode <= 38599) {
        st = 'TN';

    } else if ( (zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 88500 && zipcode <= 88599) ) {
        st = 'TX';

    } else if (zipcode >= 84000 && zipcode <= 84999) {
        st = 'UT';

    } else if (zipcode >= 5000 && zipcode <= 5999) {
        st = 'VT';

    } else if ( (zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode === 20598) ) {
        st = 'VA';

    } else if ( (zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999) ) {
        st = 'DC';

    } else if (zipcode >= 98000 && zipcode <= 99499) {
        st = 'WA';

    } else if (zipcode >= 24700 && zipcode <= 26999) {
        st = 'WV';

    } else if (zipcode >= 53000 && zipcode <= 54999) {
        st = 'WI';

    } else if (zipcode >= 82000 && zipcode <= 83199) {
        st = 'WY';

    } else {
        st = 'none';
    }
  
    return st;
  }





  var fnc_scrollto = (type, arg, offSet = 0, paralax=false) => {
    let to = arg;
    if (type === 'ref') to = window.scrollY + arg.current.getBoundingClientRect().top;
    to += offSet


    var i;
    if (paralax) {
        i = parseInt(paralax.current.scrollTop);
        if ( i !== to ) {
            to = parseInt(to);
    
                if (i < to) {
                    let int = setInterval(function() {
                        if (i > (to-10)) i += 1;
                        else if (i > (to-40)) i += 6;
                        else if (i > (to-80)) i += 16;
                        else if (i > (to-160)) i += 36;
                        else if (i > (to-200)) i += 48;
                        else if (i > (to-300)) i += 60;
                        else i += 80;
                        paralax.current.scroll(0, i);
                        if (i >= to) clearInterval(int);
                    }, 15);
                }
                else {
                    let int = setInterval(function() {
                        if (i < (to+10)) i -= 1;
                        else if (i < (to+40)) i -= 6;
                        else if (i < (to+80)) i -= 16;
                        else if (i < (to+160)) i -= 36;
                        else if (i < (to+200)) i -= 48;
                        else if (i < (to+300)) i -= 60;
                        else i -= 80;
                        paralax.current.scroll(0, i);
                        if (i <= to) clearInterval(int);
                    }, 15);
                }
            }
    }
    else {
        i = parseInt(window.pageYOffset);
    
    if ( i !== to ) {
        to = parseInt(to);

            if (i < to) {
                let int = setInterval(function() {
                    if (i > (to-10)) i += 1;
                    else if (i > (to-40)) i += 6;
                    else if (i > (to-80)) i += 16;
                    else if (i > (to-160)) i += 36;
                    else if (i > (to-200)) i += 48;
                    else if (i > (to-300)) i += 60;
                    else i += 80;
                    window.scroll(0, i);
                    if (i >= to) clearInterval(int);
                }, 15);
            }
            else {
                let int = setInterval(function() {
                    if (i < (to+10)) i -= 1;
                    else if (i < (to+40)) i -= 6;
                    else if (i < (to+80)) i -= 16;
                    else if (i < (to+160)) i -= 36;
                    else if (i < (to+200)) i -= 48;
                    else if (i < (to+300)) i -= 60;
                    else i -= 80;
                    window.scroll(0, i);
                    if (i <= to) clearInterval(int);
                }, 15);
            }
        }
    }
};


function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {

              reject(error);
              return;
            }
  

            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
}


export {stateOptions, zipState, fnc_scrollto, retry};
