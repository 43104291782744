import './App.css';
import NavBar from './components/NavBar';
import {useHistory, Switch, Route, Redirect, useLocation} from  'react-router-dom';
import Home from './Pages/Home';
import {Suspense, lazy} from 'react'
import { AnimatePresence } from "framer-motion";
import {retry} from './components/miscItems.js'




const Products = lazy(()  => retry(() => import('./Pages/Products')));
const ProductDisplay = lazy(() => retry(() =>  import('./Pages/ProductDisplay')));
const TermsOfService = lazy(() => retry(() => import('./Pages/TermsOfService')));
const PrivacyPolicy = lazy(() => retry(() => import('./Pages/PrivacyPolicy')));

const EditCartPage = lazy(() => retry(() => import('./Pages/EditCartPage')));
const ShippingReturns = lazy(() => retry(() => import('./Pages/ShippingReturns')));
const ReturnsPolicy = lazy(() => retry(() => import('./Pages/ReturnsPolicy')));
const ConfirmEmail = lazy(() => retry(() => import('./Pages/ConfirmEmail')));

const NotFound = lazy(() => retry(() => import('./Pages/NotFound')));
const About2 = lazy(() => retry(() => import('./Pages/About2')));
const Checkout = lazy(() => retry(() => import('./components/Checkout')));

function App() {

  const location = useLocation();
  const history = useHistory();


  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

  return (
    <div className="App" >

     <NavBar/>

     <Route render={()=>(
      <Suspense fallback={<div>Loading...</div>}>

<AnimatePresence exitBeforeEnter initial={false}>

      <Switch location={location} key={location.pathname}>
                <Route path = "/" exact component={Home}/>
                <Route path = "/Shop" exact component={Products}/>
                <Route path = "/Shop/:productName"  component={ProductDisplay}/>
                <Route path = "/Cart/Checkout" exact component={Checkout}/>
                <Route path = "/TermsofService" exact component={TermsOfService}/>
                <Route path = "/Cart" exact component={EditCartPage}/>
                <Route path = "/PrivacyPolicy" exact component={PrivacyPolicy}/>
                <Route path = "/ShippingPolicy" exact component={ShippingReturns}/>
                <Route path = "/ReturnPolicy" exact component={ReturnsPolicy}/>
                <Route path = "/SubscribeEmail" exact component={ConfirmEmail}/>
                <Route path = "/About" exact component={About2}/>



                <Route component={NotFound} />


                <Redirect from="/index.html" to="/" />


                </Switch>

                </AnimatePresence>
                </Suspense>

       )}/>


         
  
      

    </div>
  );
}

export default App;
