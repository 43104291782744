import React, {useContext, useEffect} from 'react';
import '../App.css';
import Hero from '../components/Hero';
import { NavBarContext } from '../contexts/NavBarContext';
import { motion } from "framer-motion"


function Home () {

    const setNavBarShowState = useContext(NavBarContext)[1];


    useEffect(() => {
        setNavBarShowState(0)
        window.scroll({top: 0})
        document.title = "Shop Japanese A5 Wagyu | Wagyu Outlet"

    }, [setNavBarShowState]);

    return (
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <>
        <Hero/>
        </>
        </motion.div>
    )
}
export default Home;