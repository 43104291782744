import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./NavBar.css";
import { CartContext } from "../contexts/CartContext";
import Cart from "../components/Cart";
import { NavBarContext } from "../contexts/NavBarContext";
import {AnimatePresence,  motion } from "framer-motion"
import NavMenu from './NavMenu';




function Navbar() {
  let history = useHistory();


  const navBarShowState = useContext(NavBarContext)[0];
  const setFlexGapSupport = useContext(NavBarContext)[3]
  const setShippingBanner = useContext(NavBarContext)[5];


  const [opacity, setOpacity] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarShow, setNavbarShow] = useState(false);

  const [checkFlexSupport, setCheckFlexSupport] = useState(true)
  const flexSupportRef = useRef(null)
  const [viewPortHeight, setViewPortHeight] = useState("")



  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);

  };

  const cart = useContext(CartContext)[0];
  const [cartTotal, setCartTotal] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);

  const [badgeClassNames, setBadgeClassNames] = useState("notiBadge");

  const isFirstUpdate = useRef(true);



  const [showShippingBanner, setShowShippingBanner] = useState(false)




  useEffect(() => {

    setTimeout(() => {
      setShowShippingBanner(true)
    }, 600);
  }, []);



  useEffect(() => {
    const currentCartTotal = Object.keys(cart).reduce((acc, key) => {
      return acc + cart[key].qty;
    }, 0);
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    if (currentCartTotal > cartTotal) {
      onAnimationStart();
    }
    setCartTotal(currentCartTotal);
  }, [cart, cartTotal]);

  const openCart = () => {
    setCartOpen(!cartOpen);
  };
  const closeCart = () => {
    setCartOpen(false);
  };
  const onAnimationStart = () => {
    setBadgeClassNames("notiBadge badgeAnimation");
  };
  const onAnimationEnd = () => {
    setBadgeClassNames("notiBadge");
  };

  const [style3ScollOpacity, setStyle3Opacity] = useState(0) 

  const[initialLoad, setIniitalLoad] = useState(true)
  const[previouslyLoaded, setPreviouslyLoaded] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const[mobileWidth, setMobileWidth] = useState(false)

  const prevScrollY = useRef(0);

  const [mobileNavbar, setMobileNavbar] = useState(true);






  useEffect(() => {

    const unlisten = history.listen((location, action) => {
      closeCart()
      closeMenu()
    })

    const getWindowDimensions = ()=> {

      setMobileWidth(window.innerWidth<760 || window.innerHeight<260)
      setViewPortHeight(window.innerHeight.toString()+"px")

      if(window.innerWidth>760 && window.innerHeight>260 && !mobileNavbar){
       setMobileNavbar(true)
      }
    }
    setViewPortHeight(window.innerHeight.toString()+"px")
  


    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && currentScrollY > 50 && mobileNavbar && mobileWidth && !cartOpen && !menuOpen) {
        setMobileNavbar(false);
      }
      if ((prevScrollY.current > currentScrollY || currentScrollY < 50)&& !mobileNavbar && mobileWidth) {
        setMobileNavbar(true);
      }

      let scrollY = window.scrollY
      if (scrollY > 1){
        setStyle3Opacity(1)
      }
      else{
        setStyle3Opacity(0)
      }

      prevScrollY.current = currentScrollY;
    };



    if (navBarShowState===0 && initialLoad){
      setPreviouslyLoaded(true)
    }
    if (navBarShowState!==0 && previouslyLoaded){
      setIniitalLoad(false)
    }

 



    if (firstLoad) {
      setFirstLoad(false)
      setFlexGapSupport(flexSupportRef.current.scrollHeight)
      setCheckFlexSupport(false)
    }



    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener('resize', getWindowDimensions, { passive: true });


    setTimeout(() => {
      getWindowDimensions()



      const parallax = document.getElementById("parallax");

      const navbarState = () => {
        if (parallax.scrollTop >= 190) {
          setOpacity(1);
          setNavbarShow(true);
        } else if (parallax.scrollTop >= 40) {
          setOpacity((parallax.scrollTop - 40) / 150);
          setNavbarShow(false);
        } else {
          setOpacity(0);
          setNavbarShow(false);
        }
      };

      if (parallax) {
        parallax.addEventListener("scroll", navbarState, { passive: true });
        return () => {
          parallax.removeEventListener("scroll", navbarState);
        };
      } else {
        setOpacity(1);
      }


    }, 400);

    return () => {

      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', getWindowDimensions);
      unlisten();
    };


  }, [history, cartOpen, menuOpen, mobileWidth, mobileNavbar, navBarShowState, firstLoad, initialLoad, previouslyLoaded, setFlexGapSupport]);




  
   

  const style = {
    background: `rgba(0, 0, 0, ${navBarShowState === 0 ? opacity : navBarShowState === 3 ? style3ScollOpacity: 1})`,
    top: !mobileNavbar?  "-5.75rem": showShippingBanner?  navBarShowState===2 || navBarShowState===3?"0":"2rem":"0"
  };


  const bannerStyle = {
    top: !mobileNavbar?  "-7.75rem": "0"
  };

 

  const closeTheMenu = () => {
    setCartOpen(false);
    setMenuOpen(false)
  };


  const closeShippingBanner = () => {
    setShowShippingBanner(false)
    setShippingBanner(false)
  }


  
  return (
    <>

      <div className={`freeShippingBanner ${!showShippingBanner?"shippingBannerCollapse":navBarShowState===2||navBarShowState===3?"shippingBannerCollapse":undefined}`}
      style={bannerStyle}
      >
          <span className="bannerLeft">
          <br/>

          </span>

          <Link to="/Shop" href="/Shop" className="bannerCenter" onClick={()=>closeShippingBanner()} aria-label="Wagyu Outlet Home">
          Free Shipping on Orders Over $250
        </Link>
          <span className="bannerRight"
           onClick={()=>closeShippingBanner()}
          >
             <div
                 className= "cloaseBannerEx"
                
                />
          <br/>

          </span>
        </div>

      <nav className={`navbar ${navbarShow ? "active" : undefined}`} style={style}>



        
        {checkFlexSupport &&
        <div ref={flexSupportRef} className="flexSupportCheck">
          <div/>
          <div/>
        </div>
          }


        <Link to="/" href="/" className="logo" onClick={closeMenu} aria-label="Wagyu Outlet Home">
          <img className="logoImage" width="783px" height="584px" src="/images/logo1.png" alt="Wagyu Outlet Logo" />
        </Link>

        <div
          className={`menu-icon  ${navBarShowState === 2? "navBarHidden":undefined}`}
          onClick={openMenu}
        >
          <i className="fa fa-bars"></i>
        </div>

        <ul
          className={`${menuOpen ? "menu" : "menu"}  ${
            navBarShowState === 2? "navBarHidden":undefined
          }`}
          style={(navBarShowState === 0 && initialLoad)?{opacity:0,
            animation: `parallaxFadeIn .75s ease-out 2.5s forwards`
           }: undefined}
        >

          <li className="navLink">
            <Link to="/" className="navLinks" href="/" onClick={closeMenu} aria-label="Wagyu Outlet Home">
              <div className="fancyFont">Home</div>{" "}
            </Link>
          </li>
          <li className="navLink" >
            <Link
              to="/Shop#all"
              className="navLinks"
              href="/Shop#all"
              onClick={closeMenu}
              aria-label="Shop Japanese Wagyu and Argentinean Beef"
            >
              <div className="fancyFont">Shop</div>{" "}
            </Link>
          </li>
          <li className="navLink">
            <Link
              to="/About"
              className="navLinks"
              href="/services"
              onClick={closeMenu}
              aria-label="About Our Beef"
            >
              <div className="fancyFont">About</div>{" "}
            
            </Link>
          </li>
        </ul>
        <div
          className={`cart  ${navBarShowState === 2? "navBarHidden":undefined}`}
          onClick={() => openCart()}
          style={(navBarShowState === 0 && initialLoad)?{opacity:0,
            animation: `parallaxFadeIn .75s ease-out 2.5s forwards`
           }: undefined}
        >
          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
          {Object.keys(cart).length ? (
            <div
              className={badgeClassNames}
              onAnimationEnd={() => onAnimationEnd()}
            >
              {cartTotal}
            </div>
          ) : (
            void 0
          )}
        </div>

  


     <AnimatePresence>
          {cartOpen && (

          <motion.div 
          key={"cartMask"}
          className="cartMask"
          onClick={() => closeCart()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}/>

          )}


        {menuOpen && (

        <motion.div 
          key={"cartMask"}
          className="cartMask"
          onClick={() => closeMenu()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}/>
        )}
          </AnimatePresence>


         <NavMenu cartCloseMenu={closeTheMenu} shouldShow={menuOpen} viewPortHeight={viewPortHeight}/>

        <Cart cartCloseMenu={closeTheMenu} shouldShow={cartOpen} viewPortHeight={viewPortHeight}/>
      </nav>

    </>
  );
}

export default Navbar;
