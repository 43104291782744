import React, { useState, useEffect, createContext } from "react";



export const CartContext = createContext();

export const CartProvider = (props) => {
    const [cart, setCart] = useState({})


    useEffect(() => {
        var retrievedCart = localStorage.getItem('cart');
        if (retrievedCart){

            setTimeout(()=> { 

                setCart(JSON.parse(retrievedCart));

            }, 500)
        }
      }, []);

    return (
        <CartContext.Provider value={[cart, setCart]}>
        {props.children}
        </CartContext.Provider>
    )
}